<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div
  .mb-3
  .container.p-4.bg-white.shadow-sm.rounded
    h5.title.pb-4.pt-2 고객 아이디 조회
      span.text-muted.ml-4 ID: {{record_id}}
    //- strong.text-secondary(style='opacity: 0.5') 같은 아이디를 가진 고객을 모든 목록에서 조회합니다.
    .mb-2
    //- p 모든 연결된 레코드, 레코드 종류별로
    //- pre {{rows}}
    table.table
      thead
        tr
          th 목록
          th 제목
          th 내용
      tbody
        tr(v-for='row in rows')
          td.w-25
            router-link.btn.btn-light.text-left.btn-block(:to='`/property/${property_id}/customer/${row.document_id}/${$options.filters.encodeText(row.document.name)}`') {{row.document.name}}
          td.w-25
            router-link.btn.btn-light.text-left.btn-block(:to='`/property/${property_id}/customer/${row.document_id}/${$options.filters.encodeText(row.document.name)}/${row.id}/${row.external_id}`')
              span.mr-1(v-for='col in row.document.config.cols' v-if='col.position==`primary`') {{row.json[col.key]}}
          td
            //- span.mr-3(v-for='col in row.document.config.cols' v-if='col.position==`secondary`') {{col.label}}: {{row.json[col.key]}}
            .row
              .col-3.col-lg(v-for='col in row.document.config.cols' v-if='col.position==`secondary`' style='min-width: 120px; padding-bottom: 0.5rem')
                strong.d-block {{col.label}}
                span.text-muted.text-break {{row.json[col.key] || '-'}}
  //- (style='margin-top: -61px; position: relative; z-index: 1')
  //- div.container-search.bg-white.shadow-sm.pl-2
    router-link(:to='`/property/${property_id}/activity`')
      span.text-dark(style='font-size: 18px; line-height: 50px')
        b-icon-chevron-left
        | 뒤로
    strong.text-secondary.ml-4.mr-2 {{document.name}}

  //- form(@submit.prevent='submit')
    table.table(v-if='row.id')
      tbody
        tr
          th.bg-light.w-25.text-right ID
          td
            input.form-control(type='text' v-model='row.external_id' readonly autofocus)
        tr(v-for='(col, $index) in document.config.cols')
          th.bg-light.w-25.text-right {{col.label}}
          td
            input.form-control(type='text' v-model='row.json[col.key]')
        tr
          th
          td
            button.btn.btn-primary(type='submit' :disabled='saving') {{saving_text}}
            button.btn.btn-default.text-danger.float-right(type='button' @click='remove') 삭제


</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'on_updated'],
  components: {

  },
  computed: {

  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
    'record_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      saving: false,
      saving_text: '저장',
      document: {},
      row: {},

      rows: [],
    }
  },
  async mounted() {
    // await this.$store.dispatch('documents', this.property_id)
    // await this.$store.dispatch('status flows', this.property_id)
    // this.loaded = true
    // this.load()
    if (this.$store.state.documents) {
      this.load()
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v1/property/${this.property_id}/views/documents/group-records/${decodeURIComponent(this.record_id)}`, {

        })
        if (r?.data?.message != 'ok') throw new Error('고객 데이터 가져오기 실패')
        this.rows = r.data.rows.map(e => {
          e.document = this.$store.state.documents_by_id[e.document_id]
          e.json = JSON.parse(e.json)
          return e
        })

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
  },
}
</script>
